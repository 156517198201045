import React from 'react'
import {Component} from "./style"
import ImageView from "../../../../assets/images/list_empty.png"
import { useTranslation } from 'react-i18next';

const Index = ({height, top}) => {
    const { t } = useTranslation();
   
    return (
        <Component height={height} top={top} >
            <div className="wrapper">
                <img src={ImageView} alt="empty iamge"/>
                <div className="title">{t("empty_list.empty_list")}</div>
            </div>
        </Component>
    )
}

export default Index
