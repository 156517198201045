import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {makeStyles} from '@material-ui/core';
import Map from "./positon.map.google"
import { getResponse } from "../../../requests/getApi"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {VALUES, DARKVALUES, GREENVALUES} from '../../../constants/styleConstants';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const theme = useSelector(state => state.theme)
  let colorValues;
  if (theme.name === 'dark') {
      colorValues = VALUES;
  } else if (theme.name === 'green') {
      colorValues = GREENVALUES;
  } else {
      colorValues = DARKVALUES;

  }
  const useStyles = makeStyles((theme) => ({
    colorMenu: {
        background:colorValues.colorBgPrimary,
        color:colorValues.colorHeader
    },
  }))
  const classes = useStyles();
  const { t } = useTranslation();
  const { openModal, setOpenModal } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };
  React.useEffect(() => { setOpen(openModal) }, [openModal])
  const [state, setState] = React.useState({ success: '', error: '' })

  React.useEffect(() => {
    if (open) {
      getResponse('/positions/last_locations/?page=1&page_size=10', setState)
      const intervalId = setInterval(() => {
        getResponse('/positions/last_locations/?page=1&page_size=10', setState)
      }, 1000 * 15) // in milliseconds
      return () => clearInterval(intervalId)
    }
  }, [open])
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} >
          <Toolbar className={classes.colorMenu}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" >
              {state?.success !== '' ? `${t('sidebar.couriers')}` : state?.error !== '' ? <span style={{ color: '#ffeb00' }}>Network error</span> : null}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List style={{ padding: 0 }} >
          <Map dataPositions={state?.success?.data || []} />
        </List>
      </Dialog>
    </div>
  );
}

