import {
    CHANGE_THEME
} from '../actionTypes';

const initialValue={
    name:"dark"
}

export default function themeReducer(state=initialValue, action) {
    switch (action.type) {
        case CHANGE_THEME:
            return {
                ...state, 
                name:action.payload
            };

        default:
            return state;
    }
}