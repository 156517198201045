import {
    CHANGE_THEME
} from "../actionTypes";

export const changeTheme = (name) => {
    localStorage.setItem('Mode',name)
    return {
        type: CHANGE_THEME,
        payload: name
    };
};