import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Variants() {
  return (
    <Stack spacing={0}>
      <Skeleton width={450} height={80} animation="wave" variant="text" />
      <Skeleton width={450} height={500} animation="wave" variant="rectangular" />
    </Stack>
  );
}