import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTranslation } from 'react-i18next';
import "./timeline.order.css"

export default function BasicTimeline ( { dataById } ) {
  const { t } = useTranslation();
  const status = JSON.parse(localStorage.getItem('status'))
  function checkTypeMessage(data, assignFirstName, assignLastName, old_status, new_status){
    if(data === 'create') return t('orderProps.orderCreate')
    // if(data === 'create') return "Buyurtma yaratildi"
    if(data === 'change_status') {
      if(status?.results.find(a=>a.id===old_status)?.name === 'Yangi')
        return t('orderProps.orderNewToProgress')
      if(status?.results.find(a=>a.id===old_status)?.name === 'Tayyorlanmoqda')
      return t('orderProps.orderProgressToReady')
      if(status?.results.find(a=>a.id===old_status)?.name === 'Tayyor')
      return t('orderProps.orderReadyToClosed')
    }
      // return `Buyurtma ${status?.results.find(a=>a.id===old_status)?.name} dan ${status?.results.find(a=>a.id===new_status)?.name} ga o'zgartirildi`
    if(data === 'assign') 
      return `${assignFirstName} ${assignLastName} ${t('orderProps.orderAssign')}`
    if(data === 'received') return t('orderProps.orderDeliveredToBranch')
    if(data === 'delivered') return t('orderProps.orderDeliveredToClient')
  }
  return (
    <Timeline className="timeline_inner">
      {
        dataById ? dataById?.history?.map( ( item, index ) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineConnector />
                <TimelineDot color="success" variant="outlined" size="large"/>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="t_content" sx={{ py: 2, px: 2 }}>
              <div className="t_content" style={ { display: 'flex', flexDirection: 'column' } }>
                <span className="timeline_items">{item?.date || "00-00-000"}</span>
                <span className="timeline_items">
                  { 
                    item?.event_type === "change_status" ? 
                      checkTypeMessage(item?.event_type, dataById?.assign?.first_name, dataById?.assign?.last_name, item?.old_status, item?.new_status):
                      checkTypeMessage(item?.event_type, dataById?.assign?.first_name, dataById?.assign?.last_name)
                  }
                </span>
                <span className="timeline_items">{ dataById?.user?.username}</span>
              </div>
            </TimelineContent>
          </TimelineItem>
        ) ):<div>Ma'lumot mavjud emas</div>
      }
    </Timeline>
  );
}
