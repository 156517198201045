export const USER_SET = 'USER_SET';
export const USER_REMOVE = 'USER_REMOVE';
export const SET_ORDER_BASKET = 'SET_ORDER_BASKET';

export const ADD_ORDER = 'ADD_ORDER';
export const ADD_EDIT_ORDER = 'ADD_EDIT_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const QUANTITY_ORDER = 'QUANTITY_ORDER';
export const MODIFICATION_ORDER = 'MODIFICATION_ORDER';
export const TOTAL_ORDER = 'TOTAL_ORDER';
export const SELECTED_ORDER = 'SELECTED_ORDER';
export const ORDER_DETAILS = 'ORDER_DETAILS';

// KANBAN
export const SELECTED_KANBAN = 'SELECTED_KANBAN';
export const SELECTED_CALL = 'SELECTED_CALL';
export const SELECTED_ADDRESS_TEXT = 'SELECTED_ADDRESS_TEXT';
export const SELECTED_BRANCH = 'SELECTED_BRANCH';
export const SELECTED_COURIER = 'SELECTED_COURIER';
export const SELECTED_TARIFF = 'SELECTED_TARIFF';

export const SELECTED_CALL_CLEAR = 'SELECTED_CALL_CLEAR';
export const SELECTED_TARIFF_CLEAR = 'SELECTED_TARIFF_CLEAR';
export const SELECTED_COURIER_CLEAR = 'SELECTED_COURIER_CLEAR';
export const SELECTED_KANBAN_CLEAR = 'SELECTED_KANBAN_CLEAR';

export const COURIER_DIALOG = 'COURIER_DIALOG';
export const TARIFF_DIALOG = 'TARIFF_DIALOG';
export const CALL_DIALOG = 'CALL_DIALOG';
export const USER_DIALOG = 'USER_DIALOG';
export const CHANGE_THEME = 'CHANGE_THEME';



