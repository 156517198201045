import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    Paper,
    Checkbox,
    Box,
    Button,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

import Confirm from '../../../components/Confirm';
import Alert from '../../../components/Alert';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../constants/styleConstants';
import { useDispatch, useSelector } from 'react-redux';
import { orderDetails } from '../../../redux/actions/OrderActions';
import styled from '@material-ui/core/styles/styled';

// Utility Functions
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    console.log(array, "this is array---------")
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// Header

function EnhancedTableHead(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useTableHeadStyles = makeStyles((theme) => ({
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },

        colorPrimary: {
            background: colorValues.colorBgPrimary,
            color: colorValues.colorHeader,
        },
        c: {
            '& path': {
                fill: colorValues.colorHeader,
            },
        },
    }));
    const classes = useTableHeadStyles();

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.colorPrimary}>
            <TableRow>
                {props.columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            classes={{ icon: classes.c }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography className={classes.colorPrimary}>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// Styles

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(230, 246, 255, 0.5)',
        border: 'none',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function EnhancedTable(props, { isOrder }) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },

        //
        boxBtns: {
            marginBottom: '10px',
        },
        boxBtn: {
            marginRight: '5px',
        },
        boxTable: {
            height: 1170,
            width: '100%',
            marginTop: '10px',
            overflow: 'auto hidden',
        },
        tablePagination: {
            background: colorValues.colorBgPrimary,
            color: colorValues.colorHeader,
        },
        progress: {
            position: 'absolute',
            top: '20%',
            left: '46%',
        },
        hidden: {
            display: 'none',
        },
        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));


    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    // pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const rowsInPage = 30;

    useEffect(() => {
        const current = props.rows.length;
        const required =
            props.count > 0
                ? (page + 1) * rowsPerPage > props.count
                    ? props.count
                    : (page + 1) * rowsPerPage
                : (page + 1) * rowsPerPage;
        const diff = required - current;
        // const cPage = Math.ceil((page * rowsPerPage) / rowsInPage + 1);
        const cPage = Math.ceil(current / rowsInPage + 1);

        if (diff > 0) {
            const arr = [];
            for (let i = cPage; i < cPage + Math.ceil(diff / rowsInPage); i++) {
                if (i > current / rowsInPage) arr.push(i);
            }
            if (arr.length > 0) props.setPagesToFetch(arr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    // table functionality
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const selectHistory = (row) => {
        // console.log(row);
        dispatch(orderDetails(row));
        history.push(`/history/${row.id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

    // logic
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

    function handleClickAddBtn() {
        props.handleFormOpen({ isNew: true });
    }

    function handleClickEditBtn() {
        props.handleFormOpen({ id: selected[0] * 1 });
    }

    function handleClickDeleteBtn() {
        setDeleteConfirmOpen(true);
    }

    function handleConfirmDelete() {
        const prms = [];
        selected.forEach((el) => {
            prms.push(props.reqDelete(el * 1));
        });

        Promise.all(prms)
            .then((responds) => {
                responds.forEach((res) => {
                    if (res.status <= 200 || res.status > 300)
                        setAlert({
                            open: true,
                            severity: 'error',
                            message: Object.values(res.data)[0],
                        });
                });
                setAlert({ open: true, severity: 'success', message: 'Deleted' });

                setSelected([]);
                setDeleteConfirmOpen(false);
                console.log('im callin');
                props.refreshPage();
            })
            .catch((err) => console.error(err));
    }

    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    return (
        <div className={classes.root}>
            {/*<Box className={classes.boxBtns}>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        className={clsx(classes.boxBtn)}*/}
            {/*        startIcon={<AddIcon />}*/}
            {/*        onClick={handleClickAddBtn}*/}
            {/*    >*/}
            {/*        {t('table.buttons.add')}*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        className={clsx(classes.boxBtn, [selected.length !== 1 && classes.hidden])}*/}
            {/*        startIcon={<EditIcon />}*/}
            {/*        onClick={handleClickEditBtn}*/}
            {/*    >*/}
            {/*        {t('table.buttons.edit')}*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="secondary"*/}
            {/*        className={clsx(classes.boxBtn, [selected.length < 1 && classes.hidden])}*/}
            {/*        startIcon={<DeleteIcon />}*/}
            {/*        onClick={handleClickDeleteBtn}*/}
            {/*    >*/}
            {/*        {t('table.buttons.delete')}*/}
            {/*    </Button>*/}
            {/*</Box>*/}

            <Paper className={classes.paper}>
                <TableContainer style={{ position: 'relative' }}>
                    <CircularProgress
                        className={clsx(classes.progress, !props.waiting && classes.hidden)}
                        size={100}
                        color="inherit"
                    />

                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rows.length}
                            columns={props.columns}
                            disableCheckbox={props.disableCheckbox}
                        />
                        <TableBody>
                            {stableSort(props.rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <StyledTableRow
                                            hover
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => selectHistory(row)}
                                            key={row.id}
                                        >
                                            {props.columns.map((el, i) => {
                                                // console.log(el.id);

                                                if (el.id === 'session') {
                                                    return (
                                                        <TableCell align="left" key={i}>
                                                            {row?.session === null ? t('profile.nodata') : row?.session}
                                                        </TableCell>
                                                    );
                                                }
                                                if (el.id === 'created_date') {
                                                    return (
                                                        <TableCell align="left" key={i}>
                                                            {new Date(row[el.id])
                                                                .toTimeString()
                                                                .slice(0, 5)}{' '}
                                                            &nbsp;&nbsp;
                                                            {moment(new Date(row[el.id])).format(
                                                                'DD MMM YYYY'
                                                            )}
                                                        </TableCell>
                                                    );
                                                } else if (el.id === 'phoneNumber') {
                                                    // console.log(row);
                                                    return (
                                                        <TableCell align="left" key={i}>
                                                            {/* {row.user.first_name} {row.user.last_name} */}
                                                            {row.user?.phone}
                                                        </TableCell>
                                                    );
                                                }
                                                return (
                                                    <TableCell align="left" key={i}>
                                                        {row[el.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </StyledTableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    classes={{ toolbar: classes.tablePagination }}
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    component="div"
                    labelRowsPerPage={t('table.rows_per_page')}
                    count={props.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Confirm
                open={deleteConfirmOpen}
                header={t('table.deleteConfirm.header')}
                text={t('table.deleteConfirm.text')}
                handleClose={() => {
                    setDeleteConfirmOpen(false);
                }}
                handleConfirm={handleConfirmDelete}
            />
            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </div>
    );
}
