import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../components/TableView';
import FormController from '../components/FormController';
import UsersForm from './forms/UsersForm';
import { userPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    userGet,
    // userGetById,
    userPost,
    userPut,
    userDelete
} from '../../../utils/apiRequest';

export default function Users() {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);

    const { t } = useTranslation();
    const columns = getColumns('user', t);

    useEffect(() => {
        setWaiting(true);

        const prms = [];
        for (let i of pagesToFetch) prms.push(userGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setData([...data, ...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesToFetch]);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh]);
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={userDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
            />
            <FormController
                open={formOpen}
                data={data}
                propList={propList}
                header={t('propList.user')}
                render={(input, handleTextChange, handleFileChange) => (
                    <UsersForm
                        input={input}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => setRefresh(!refresh)}
                reqPost={userPost}
                reqPatch={userPut}
            />
        </>
    );
}
