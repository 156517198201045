import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Category from "../../components/product.filter"
import { Grid, TextField, Divider, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { goodsGetByItem } from '../../../../utils/apiRequest';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { getResponse } from '../../../../requests/getApi';


export default function UsersForm(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        divider: {
            margin: '20px 0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        sectionHeader: {
            margin: '-20px auto 10px auto',
            textAlign: 'center',
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
    }));
    const [vendors, setVendors] = useState('')
    const [unitTypes, setUnitTypes] = useState('')
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => { goodsGetByItem().then(res => props?.setCategories(res.data.results)) }, [])

    useEffect(() => {
        if (props?.input && props?.setPostData) {
            props?.setPostData(props.input)
        }
    }, [])

    useEffect(() => {
        getResponse(`/vendors/`, setVendors)
        getResponse(`/unit-type/`, setUnitTypes)
    }, [])
    console.log(unitTypes)
    console.log(props.input)
    return (
        <>
            <Grid container justify="space-around">
                <Grid container justify="space-around">
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            id="name"
                            data-testid="inputt"
                            label={t('categoryProps.name')}
                            type="text"
                            value={props.input['name'] || ''}
                            onChange={props.handleTextChange('name')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5} className={classes.rightContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name_ru"
                            label={t('categoryProps.name_ru')}
                            type="text"
                            value={props.input['name_ru'] || ''}
                            onChange={props.handleTextChange('name_ru')}
                            margin="dense"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name_uz"
                            label={t('categoryProps.name_uz')}
                            type="text"
                            value={props.input['name_uz'] || ''}
                            onChange={props.handleTextChange('name_uz')}
                            margin="dense"
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="name_en"
                            label={t('categoryProps.name_en')}
                            type="text"
                            value={props.input['name_en'] || ''}
                            onChange={props.handleTextChange('name_en')}
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="name"
                        label={t('categoryProps.description')}
                        type="text"
                        value={props.input['description'] || ''}
                        onChange={props.handleTextChange('description')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_ru"
                        label={t('categoryProps.description_ru')}
                        type="text"
                        value={props.input['description_ru'] || ''}
                        onChange={props.handleTextChange('description_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={t('categoryProps.description_uz')}
                        type="text"
                        value={props.input['description_uz'] || ''}
                        onChange={props.handleTextChange('description_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_en"
                        label={t('categoryProps.description_en')}
                        type="text"
                        value={props.input['description_en'] || ''}
                        onChange={props.handleTextChange('description_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={2}>
                    <FormControl variant="outlined" fullWidth size="small" margin="dense">
                        <InputLabel id="demo-simple-select-outlined-label">{t('goodsProps.category')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // defaultValue={props?.input?.category?.id}
                            value={props?.input?.category?.id || props.input['category']}
                            onChange={props.handleTextChange('category')}
                            label={t('productProps.category')}
                            required
                        >
                            <MenuItem value="">
                                <em>{t('userProps.select')}</em>
                            </MenuItem>
                            {props?.categories?.map(result => {
                                return (
                                    <MenuItem key={result.id} value={result.id}>{result.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="price"
                        label={t('productProps.price')}
                        required
                        type="number"
                        value={props.input['price'] || ''}
                        onChange={props.handleTextChange('price')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={2}>
                    <FormControl variant="outlined" fullWidth size="small" margin="dense">
                        <InputLabel id="demo-simple-select-outlined-label">{t('sidebar.unit_type')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props?.input?.unit_type?.id || props.input['unit_type']}
                            onChange={props.handleTextChange('unit_type')}
                            label={t('sidebar.unit_type')}
                            required
                        >
                            <MenuItem value="">
                                <em>{t('userProps.select')}</em>
                            </MenuItem>
                            {unitTypes?.success?.data?.results?.map(result => {
                                return (
                                    <MenuItem key={result.id} value={result.id}>{result.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2}>
                    <FormControl variant="outlined" fullWidth size="small" margin="dense">
                        <InputLabel id="demo-simple-select-outlined-label">{t('goodsProps.vendor')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props?.input?.vendor?.id || props.input['vendor']}
                            onChange={props.handleTextChange('vendor')}
                            label={t('productProps.vendor')}
                            required
                        >
                            <MenuItem value="">
                                <em>{t('userProps.select')}</em>
                            </MenuItem>
                            {vendors?.success?.data?.results?.map(result => {
                                return (
                                    <MenuItem key={result.id} value={result.id}>{result.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}
