import { Card, CardMedia, CardContent, Typography, makeStyles, Divider } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

//
const useStyles = makeStyles((theme) => ({
    root: {
        // minWidth: '250px',
        borderRadius: '10px',
        padding: '0 15px 0 20px',
        overflow: 'visible',
    },
    firstLine: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardMedia: {
        width: '90px',
        minWidth: '90px',
        height: '90px',
        transform: 'translate(0, -30px)',
        borderRadius: '6px',
        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        fontSize: '50px',
    },
    content: {
        maxWidth: '300px',
        padding: '10px !important',
    },
    text: {
        textAlign: 'right',
    },
    footer: {
        padding: '10px 0 7px 7px',
        display: 'flex',
        alignItems: 'center',
    },
    footerIcon: {
        marginRight: '10px',
        fontSize: '20px',
    },
}));

//

export default function Dashboard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <div className={classes.firstLine}>
                <CardMedia className={classes.cardMedia} style={{ background: props.color }}>
                    {props.icon(classes.icon)}
                </CardMedia>
                <CardContent className={classes.content}>
                    <Typography className={classes.text} variant="h6">
                        {props.header}
                    </Typography>
                    <Typography className={classes.text} color="textSecondary" component="p">
                        {props.text}
                    </Typography>
                </CardContent>
            </div>
            <Divider />
            <div className={classes.footer}>
                <InfoIcon className={classes.footerIcon} />
                {props.footer}
            </div>
        </Card>
    );
}
