import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Grid,
    LinearProgress, TextField
} from '@material-ui/core';

import { getLang } from '../../../../../utils/Cookies';
import {
    editQuantityOrder,
} from '../../../../../redux/actions/OrderActions';
import Loader from "react-loader-spinner";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Close, ArrowBack, ArrowForward, Add, Delete, Add as AddIcon } from '@material-ui/icons';
import DialogActions from '@material-ui/core/DialogActions';
import orderCategory from "../../../../../assets/images/orderCategory.png";
import orderProduct from "../../../../../assets/images/orderProduct.png";
import { useTranslation } from 'react-i18next';
const styleDisable = {
    opacity: 0.5,
    pointerEvents: 'none',
    background: "#8d8c8c5a",
}
const useStyles = makeStyles({
    dialog: {
        maxWidth: 800,
        margin: 'auto'
    },
    dialogContent: {
        padding: '16px 26px'
    },
    dialogTitle: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex!important',
        justifyContent: 'space-between',
    },
    card: {
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
    },
    cardContent: {
        flex: 8 / 10,
        padding: '16px !important',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#166280",
        background: "rgba(0,250,154,0.1)",
        transition: "0.3s",
        "&:hover": {
            color: "#46f8f8",
        },
    },
    cardContent1: {
        flex: 2 / 10,
        borderRadius: 0,
        color: "#166280",
        background: "rgba(0,250,154,0.1)",
        "&:hover": {
            color: "#05ffff",
            background: "rgba(0,250,154,0.1)",
        },
    },
    cardActive: {
        background: "rgba(0,250,154,0.5)",
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem'
    },
    orderCategory: {
        boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
        borderRadius: '50%',
    },
    dialogAction: {
        background: "rgba(0,0,0,0.05)",
    }
});

function Categories({ productCategories, lang, handleClick, handleClickSelf, modalLoad, setModalLoad, disableId }) {
    const classes = useStyles();
    return (
        <Grid container spacing={2} style={{ minWidth: 500 }}>
            {
                modalLoad ?
                    <div style={{ width: '100%', height: '100%', lineHeight: '100%', textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                        <Loader type="BallTriangle" color="#166280" height={30} width={30} />
                    </div>
                    :
                    productCategories.length > 0 ?
                        productCategories.map((category) => (
                            <Grid item sm={6} md={6} key={category.id}>
                                <Card elevation={0} className={classes.card} style={category?.id === disableId ? styleDisable : {}} >
                                    <CardContent className={classes.cardContent} onClick={() => handleClickSelf(category?.id, category?.name)}>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <img className={classes.orderCategory} src={orderCategory}  data-testid="images"  width={40} height={40} alt="Logo" />
                                            <div style={{ marginLeft: "0.5rem", fontSize: "1rem", fontWeight: "bold" }}>
                                                {category[`name_${lang ? lang : "uz"}`]}
                                            </div>
                                        </div>
                                    </CardContent>
                                    <IconButton className={classes.cardContent1} onClick={() => handleClick(category?.id)}>
                                        <ArrowForward />
                                    </IconButton>
                                </Card>
                            </Grid>
                        )) : <div style={{ width: '100%', height: '100%', lineHeight: '100%', textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                            no data
                        </div>
            }
        </Grid>
    );
}

function Products({ list, lang }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const orders = (state) => state.orders;
    const ordersData = useSelector(orders);

    const dispatch = useDispatch();

    const [selected,] = useState([]);
    const [quantityDialog, setQuantityDialog] = useState(false);
    const [, setQuantity] = useState(null);

    function handleClick(obj) {
        console.log(obj);
    }
    let quant = null;

    function handleInputChange(e) {
        setQuantity(e.target.value);
        quant = e.target.value;
    }
    function handleDispatch(id) {
        dispatch(editQuantityOrder({ quant, id }));
    }
    const handleCloseQuantity = () => {
        setQuantityDialog(false);
    }

    return (
        <Grid container spacing={2}>
            {
                list.map((product) => (
                    <Grid item sm={6} key={product.id}>
                        <Card elevation={0}>
                            <CardContent className={
                                ordersData.selectedOrders.includes(product.id) ?
                                    `${classes.cardContent} ${classes.cardActive}` : classes.cardContent}
                            >
                                <div style={{ display: "flex" }}>
                                    <img  className={classes.orderCategory} src={orderProduct} width={40} height={40}  alt="products"/>
                                    <div style={{ marginLeft: "0.5rem" }}>
                                        <div style={{ fontSize: "14px", fontWeight: "bold" }}>{product[`name_${lang ? lang : "uz"}`]}</div>
                                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>Price: {product['price']}</div>
                                    </div>
                                </div>
                                {ordersData.selectedOrders.includes(product.id) ? (
                                    <IconButton onClick={() => handleClick(product)}>
                                        <Delete />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handleClick(product)}>
                                        <Add />
                                    </IconButton>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
            <Dialog
                maxWidth={'md'}
                open={quantityDialog}
                onClose={handleCloseQuantity}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                    <div style={{ fontSize: "1.2rem" }} className={classes.sectionHeader}>
                        {t('orderProps.addQuantity')}
                    </div>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseQuantity}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>

                    <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        size="small"
                        label={t('orderProps.quantity')}
                        defaultValue={1}
                        type="number"
                        name="quantity"
                        style={{ marginTop: "1rem" }}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) => {
                            handleInputChange(e);
                            handleDispatch(selected.id);
                        }}
                    />

                </DialogContent>
                <DialogActions className={classes.dialogAction} style={{ padding: "0.5rem" }}>
                    <Button onClick={handleCloseQuantity} color="primary">
                        {t('orderProps.cancel')}
                    </Button>
                    <Button
                        onClick={handleCloseQuantity}
                        color="primary"
                        style={{ marginLeft: "1rem" }}
                        className={classes.saveBtn}
                        autoFocus>
                        {t('orderProps.saveChanges')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default function ProductDialog({ open, setOpen, productCategories = [], setProductCategories, global, setIdParent, parent, setParent, modalLoad, setModalLoad, disableId }) {
    const [lang, setLang] = useState("uz");
    const [category, setCategory] = useState(null);
    const [loading, setLoader] = useState(false);
    const [products, setProducts] = useState([]);
    const classes = useStyles();
    const { t } = useTranslation();
    const [arrowId,] = useState();
    const [historyParent, setHistoryParent] = useState([1])
    const handleClose = () => {
        setOpen(false);
        setProductCategories(global)
    };
    const handleClickSelf = (id, name) => {
        setIdParent({ id: id, name: name });
        setOpen(false)
    }
    function handleCategoryChange(parentId) {
        if (parentId) {
            setParent(parentId)
            setHistoryParent([...historyParent, parentId])
        }
    };
    const handleArrowBack = () => {
        if (historyParent[historyParent.length - 2]) {
            setParent(historyParent[historyParent.length - 2])
            let history = historyParent.pop()
        }
    }

    useEffect(() => {
        setLang(getLang());
    }, []);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={'lg'}
            className={classes.dialog}
        >
            {
                loading ? <LinearProgress /> : null
            }

            <DialogTitle className={classes.dialogTitle}>
                {category ? (
                    <div style={{
                        position: 'absolute', top: "0.5rem", left: "0.5rem"
                    }}
                        onClick={() => {
                            setCategory(null);
                        }}
                    >
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </div>
                ) : null}
                <div style={{ marginLeft: '0' }}>
                    <IconButton onClick={handleArrowBack}
                        style={
                            productCategories[0]?.parent === 1 ? { display: 'none' } :
                                { position: 'relative', bottom: 4, left: -15 }
                        }
                    >
                        <ArrowBack />
                    </IconButton>
                    <span style={{ position: 'relative', bottom: 3, left: -5 }}>Classification id:{arrowId ? arrowId : 1}</span>
                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>

                {
                    <Categories
                        lang={lang}
                        disableId={disableId}
                        productCategories={productCategories}
                        handleClick={handleCategoryChange}
                        setProductCategories={setProductCategories}
                        handleClickSelf={handleClickSelf}
                        modalLoad={modalLoad}
                        setModalLoad={setModalLoad}
                    />
                }

            </DialogContent>
        </Dialog>
    );
}