import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Grid,
    LinearProgress, TextField
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { getLang } from '../../../../utils/Cookies';
import { vendorsGet, goodsGet,goodsBalansGetByCategoryId} from '../../../../utils/apiRequest';
import {
    addOrder,
    clearOrder,
    editQuantityOrder,
    removeOrder,
    selectedOrders
} from '../../../../redux/actions/OrderActions';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Close, ArrowBack, ArrowForward, Add, Remove, Delete, Add as AddIcon } from '@material-ui/icons';
import DialogActions from '@material-ui/core/DialogActions';
import orderCategory from "../../../../assets/images/orderCategory.png";
import orderProduct from "../../../../assets/images/orderProduct.png";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: 800,
        margin: 'auto'
    },
    dialogContent: {
        padding: '16px 26px',
        width: "400px"
    },
    dialogTitle: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex!important',
        justifyContent: 'space-between',
    },
    card: {
        cursor: 'pointer',
    },
    cardContent: {
        padding: '16px !important',
        cursor: 'pointer',
        display: "flex",
        marginBottom: "10px",
        fontSize: "16px",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#166280",
        background: "rgba(0,250,154,0.1)",
        transition: "0.3s",
        "&:hover": {
            color: "lightseagreen",
        },
    },
    goodlists:{
        width:"50%",
        display:"flex",
        alignItems:"center",
    },
    cardActive: {
        background: "rgba(0,250,154,0.5)",
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem',
        marginLeft: "0.5rem"
    },
    Lists: {
        listStyleType: "none",
        width: '100%'
    },
    list: {
        padding: '9px 10px',
        borderRadius: '7px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        "&:hover": {
            backgroundColor: '#e3f2fd',
            transition: '0.3s ease-in-out',
        },
    },
    inputF: {
        justifyContent: 'space-between',
        marginBottom: '1.5rem'
    },
    addIcon: {
        cursor: "pointer",
    },

    orderCategory: {
        boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
        borderRadius: '50%',
        marginRight:"10px"
    },
    dialogAction: {
        background: "rgba(0,0,0,0.05)",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: "30%",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '7px'
    },
}))

export default function GoodsDialog({ open, setOpen, tableList, setTableList, goodsCategory }) {
    const [lang, setLang] = useState("uz");
    const [category, setCategory] = useState(null);
    const [balans, setBalans]= useState([]);
    const [loading, setLoader] = useState(false);
    const [products, setProducts] = useState([]);
    const classes = useStyles();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setCategory(null);
            setProducts([]);
        }, 300);
    };
    const [selectedItem, setSelectedItem] = useState({})
    const [goodList, setGoodList] = useState(null)
    const [goodData, setGoodData] = useState([]);
    const [state, setState] = useState({})
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    const handleOpenModal = (item) => {
        setLoader(false);
        setOpenModal(true);
        setSelectedItem(item)
    };

    useEffect(()=>{
    goodsBalansGetByCategoryId(selectedItem?.id, 1, 20, true).then((res) => {
        if (res) {
            setLoader(false);
            setBalans(res?.data)
        }
    });
    },[])
    
    var quan=0;
    const handleSave = () => {
        let obj = {
            name: selectedItem?.name,
            good_id: selectedItem?.id,
            unit_type_id: state?.good?.unit_type?.id || selectedItem?.unit_type?.id,
            quantity:parseInt(state?.quantity)-quan,
            // price: parseInt(state?.price),
            // sum: parseInt(state?.quantity) * parseInt(state?.price),
            // modified_date: state?.modified_date,
            description: state?.description,
        }
        setOpenModal(false);
        setTableList([...tableList, obj])
    }
    useEffect(() => {
        setLang(getLang());
    }, []);

    const handleChoose = (item) => {
        vendorsGet(1, item?.id).then((res) => {
            if (res) {
                setLoader(false);
                setGoodData(res);

            }
        });

        goodsGet(1, item?.id).then((res) => {
            if (res) {
                setLoader(false);
                setGoodData(res);
                setGoodList(res?.data?.results)
            }
        });
    }
    // console.log(goodsCategory?.success?.data?.results, "good cat")

    const handleSelectItem = (item) => {
        goodsGet(1, item?.id).then((res) => {
            if (res) {
                setLoader(false);
                setGoodData(res);
                setGoodList(res?.data?.results)
            }
        });
    }

    balans?.map((item)=>{
        if(item?.quantity !== null ){
            for(let i=0; i<balans.length; i++){
                quan+=item?.quantity
            }
        }
        else{
            quan= 1;
        }  }
    )
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={'lg'}
            className={classes.dialog}
        >
             {
                loading ? <LinearProgress /> : null
            }
           

            <DialogTitle className={classes.dialogTitle}>

                {goodList ? (
                    <div style={{
                        position: 'absolute', top: "0.5rem", left: "0.5rem"
                    }}
                        onClick={() => {
                            setGoodList(null);
                        }}
                    >
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </div>
                ) : null}
                <div style={{ marginLeft: '3rem' }}>
                    {!!goodList ? t('sidebar.goods') : t('orderProps.goods_category')}

                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <ul className={classes.Lists}>
                    {
                        goodList ? goodList?.map((item) => (
                            item.name && <li onClick={() => handleSelectItem(item)} key={item.id} className={classes.cardContent} >
                                <div className={classes.goodlists}>
                                    <img className={classes.orderCategory} src={orderProduct} width={40} height={40} alt="product" />{item?.name}
                                    </div>
                                <IconButton size="small" className={classes.addIcon} onClick={() => handleOpenModal(item)} >
                                    <Add />
                                </IconButton>
                            </li>
                        )) : goodsCategory?.success?.data?.results?.map((item) => (
                            item.name && <li onClick={() => handleChoose(item)} key={item.id} style={{ cursor: 'pointer' }}>
                                <div className={classes.cardContent}>
                                    <img src={orderCategory} width={40} height={40} alt="category" />
                                    {item?.name}
                                </div>
                            </li>
                        ))
                    }
                </ul>
                <Modal
                    className={classes.modal}
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                ><Fade in={openModal}>
                        <div className={classes.paper}>
                            <div className={classes.inputF}>
                                <h2>{selectedItem.name}:{quan}</h2>
                                {/* <h6></h6> */}
                                {/* <TextField
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('revisionTableProps.price')}
                                    // defaultValue={10000}
                                    type="number"
                                    name="quantity"
                                    style={{ marginRight: "1rem", marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, price: e.target.value })}
                                />
                                <br /> */}
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('revisionTableProps.quantity')}
                                    // defaultValue={1}
                                    type="number"
                                    name="quantity"
                                    style={{ marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, quantity: parseInt(e.target.value) })}
                                />
                                {/* <TextField

                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('revisionTableProps.modified_date')}
                                    // defaultValue={1}
                                    type="date"
                                    name="modified_date"
                                    style={{ marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, modified_date: e.target.value })}
                                /> */}
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('revisionTableProps.description')}
                                    type="text"
                                    name="description"
                                    style={{ marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, description: e.target.value })}
                                />
                            </div>
                            <Button size="small" onClick={handleCloseModal} color="primary">
                                {t('orderProps.cancel')}
                            </Button>
                            <Button
                                size="small"
                                onClick={handleSave}
                                color="primary"
                                style={{ marginLeft: "1rem" }}
                                className={classes.saveBtn}
                                autoFocus>
                                {t('orderProps.saveChanges')}
                            </Button>
                        </div>
                    </Fade>
                </Modal>
            </DialogContent>
            {tableList.id}
        </Dialog>
    );
}