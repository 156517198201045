import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import taxi from "../../../assets/images/taxi_2.png"
import "./style.css"
import moment from "moment"
import Loader from "react-loader-spinner";
const AnyReactComponent = ({ text, url, time }) => 
<div>
    <div className="text_map">
        <div className="text_map_name">{text}</div>
        <div>{time}</div>
    </div>
    <img width="80px" height="80px"src={url} alt="asdf"/>
</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 41.2825125,
      lng: 69.1392828
    },
    zoom: 11,

  };

  getMapOptions = (maps = GoogleMapReact) => {

    return {
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }],
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,
        minZoom: 1,
        maxZoom: 18,

        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },

        zoomControl: true,
        clickableIcons: false
    };
}
  
  render() {
    //   console.log(this.props.dataPositions)
        
    return (
      <div style={{ height: '90vh', width: '100%' }}>
         {
             this.props.dataPositions?.length > 0  ? 
            <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAUbRHtu3k_fg3jDGk_qAatE5jA4bC_ndE' }}
            defaultCenter={{
                lat: parseFloat(this.props?.dataPositions[ 0 ]?.latitude),
                lng: parseFloat(this.props?.dataPositions[ 0 ]?.longitude),
            }}
            defaultZoom={this.props.zoom}
            options={this.getMapOptions}
            >
                {
                    this.props.dataPositions?.map( ( item, index ) => (
                        <AnyReactComponent
                            key={index}
                            lat={parseFloat(item?.latitude)}
                            lng={parseFloat(item?.longitude)}
                            text={item?.user?.first_name +" "+item?.user?.last_name}
                            url={taxi}
                            time={moment( item?.created_date ).format( 'HH:mm  D MMM ' )}
                        />
                    ))
                }
              
                </GoogleMapReact>:<div style={ { width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 30 } }>  <Loader
                    type="Rings"
                    color="#00BFFF"
                    height={ 70 }
                    width={ 70 }
                    timeout={ 999000 } //3 secs
                /></div>

         } 
      </div>
    );
  }
}

export default SimpleMap;