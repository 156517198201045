import styled from "styled-components"
export const Wrapper = styled.div`
    width: 100%;
`
export const Container = styled.div`
    width:auto;
    max-width: 700px;
    min-width: 300px;
    height: auto;
    border-radius: 5px;
    padding:15px;
    /* margin:0 auto; */
    box-shadow: 0 0 7px -3px rgba(0, 0, 0, 0.5);
    .text_area{
        width:100%;
        outline: none;
        padding:10px;
        border-radius:5px;
        font-size: 16px;
    }
    .answer{
        text-align: center;
        margin-bottom: 10px;
        font-size:16px;
        color:#8f8383;
        margin-top: 15px;
    }
    .add_text{
        position:relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        margin-top: 30px;
        z-index:10;
        &:after {
            content:"";
            position:absolute;
            bottom:7px;left:35px;
            width:15px;
            height:15px;
            background-color: #fff;
            border-radius: 4px;
            transform: rotate(45deg);
            /* box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.024); */
            z-index: 0;
        }
    }
    .add_text_cont{
        border-radius:5px;
        padding:10px 14px;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.03);
    }
    .icon_add{
        margin-right:8px;
        font-size: 33px;
        cursor: pointer;
        position:relative;
        top: 0px;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.03);
        border-radius: 50%;
        background-color: transparent;
        /* width: 50px; */

        /* color: */
        /* background-color: red; */
    }
    .list_answer{
        display: flex;
        align-items: flex-start;
        width:94%;
        padding:10px;
        margin:10px 0;
        margin-left:auto;
        position:relative;
        border-radius: 5px;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.024);
        .del_icon{
            position:absolute;
            right:5px; top:5px;
        }
        /* background-color:red; */
        .list_answer_item{
            flex:1;
            width:100%;
            height: auto;
            /* background-color:red; */
            /* white-space: normal !important;  */
            /* text-overflow: ellipsis; */
            word-wrap: wrap;
        }
    }
    
    /* background-color:red; */
`