import React, { useState,useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import {
    Drawer,
    List,
    Typography,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Icon,
    Paper,
    makeStyles,
} from '@material-ui/core';
import logoSvg from '../../../assets/images/logo.png';
import dashboard from '../../../assets/images/dashboard.png';
import fastFood from '../../../assets/images/s1.png';
import category from '../../../assets/images/s2.png';
import group from '../../../assets/images/s4.png';
import list from '../../../assets/images/s3.png';
import history from '../../../assets/images/history.jpg';
import face from '../../../assets/images/s5.png';
import call from '../../../assets/images/s6.png';
import campagin from '../../../assets/images/s7.png';
import sms from '../../../assets/images/s8.png';
import survey from '../../../assets/images/survey.png';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux"
import {VALUES, DARKVALUES, GREENVALUES} from '../../../constants/styleConstants';
import './Sidebar.css';

import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const mainMenu = ['products', 'users', 'settings', 'leads', 'orders'];
const productsMenu = ['products', 'categories', 'modification', 'classification'];
const usersMenu = ['users', 'roles'];
const settingsMenu = ['branch'];
const leadsMenu = ['survey', 'notification','campaign', 'goods', 'unit_type', 'goods_category','session', 'income', "goods_analog", "vendor", "revision", "balance_of_goods"];
const ordersMenu=['orders',"history", "goods_product"]

// const leadsMenu = ['survey', 'notification','orders','campaign','unit_type'];
// const menuListIcons = [
//     <FastFoodIcon />,
//     <CategoryIcon />,
//     <ListIcon />,
//     <GroupIcon />,
//     <FaceIcon />,
//     <CallIcon />,
//     <CampaignIcon />,
//     <MessageIcon />,
// ];

// STYLES

//
// window.addEventListener('storage', () => {
//     // When local storage changes, dump the list to
//     // the console.
//     console.log(JSON.parse(window.localStorage.getItem('Mode')));
//   });

export default function Sidebar(props) {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const path = window.location.pathname.substr(1);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);


    const theme = useSelector(state => state.theme)
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }

    const drawerWidth = colorValues.sideBarWidthExpand;

    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: drawerWidth,
            height: 'auto',
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(6) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(8) + 1,
            },
        },
        paper: {
            height: '100%',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: theme.spacing(0, 0.2),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        selectedItem: {
            background: colorValues.colorSidebarSelected,
            color:colorValues.colorMenu,
            '&:hover': {
                background: colorValues.colorHeader,
                // background: "#215467",
            },
        },
        icon: {
            height: '45px',
            width: '90%',
        },
        logoImg: {
            height: '100%',
            width: '100%',
        },
        link: {
            textDecoration: 'none',
            color: colorValues.colorPrimary,
        },
        customIcon: {
            height: '2rem',
            width: '2rem',
            borderRadius: '50%',
            objectFit: 'cover',
        },
        collapse: {
            paddingLeft: '0.8rem',
        },
        textHover: {
            '&:hover': {
                color: '#0d6efd',
            },
        },
        // CUSTOMIZATION
        colorPrimary: {
            background: colorValues.colorBgPrimary,
        },

        colorSecondary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
        colorBgAlternative: {
            background: colorValues.colorBgAlternative,
        },
        listBack:{
            background:colorValues.colorBgPrimary
        }
    }));
    const classes = useStyles();

    const handleClick = () => {
        setOpen(!open);
        props.setOpenDraw(true);
    };
    const handleClick1 = () => {
        setOpen1(!open1);
        props.setOpenDraw(true);
    };
    const handleClick2 = () => {
        setOpen2(!open2);
        props.setOpenDraw(true);
    };
    const handleClick3 = () => {
        setOpen3(!open3);
        props.setOpenDraw(true);
    };
    const handleClick4 = () => {
        setOpen4(!open4);
        props.setOpenDraw(true);
    };
    useEffect(() => {
        if (!props.open) {
            setOpen(false);
            setOpen1(false);
            setOpen2(false);
            setOpen3(false);
            setOpen4(false);
        }
    }, [props.open]);
    // const menuListIcons = [
    //     <img src={face} alt="face" className={classes.customIcon} />,
    // ];
    const productIcons = [
        <img src={fastFood} alt="fast food" className={classes.customIcon} />,
        <img src={category} alt="category" className={classes.customIcon} />,
        <img src={survey} alt="moderation" className={classes.customIcon} />,
        <img src={survey} alt="classification" className={classes.customIcon} />,
    ];
    const usersIcons = [
        <img src={list} alt="list" className={classes.customIcon} />,
        <img src={group} alt="group" className={classes.customIcon} />,
    ];
    const settingsIcons = [<img src={survey} alt="branch" className={classes.customIcon} />];

const leadsIcons=[
    <img src={survey} alt="notification" className={classes.customIcon} />,
    <img src={sms} alt="sms" className={classes.customIcon} />,
    <img src={call} alt="call" className={classes.customIcon} />,
    <img src={call} alt="call" className={classes.customIcon} />,
    <img src={campagin} alt="campagin" className={classes.customIcon} />,
    <img src={campagin} alt="campagin" className={classes.customIcon} />,
    <img src={history} alt="history" className={classes.customIcon} />,
    <img src={survey} alt="notification" className={classes.customIcon} />,
    <img src={fastFood} alt="fast food" className={classes.customIcon} />,
    <img src={survey} alt="branch" className={classes.customIcon} />,
    <img src={survey} alt="moderation" className={classes.customIcon} />,
    <img src={survey} alt="moderation" className={classes.customIcon} />,

]

const ordersIcons=[
    <img src={survey} alt="branch" className={classes.customIcon} />,
    <img src={history} alt="history" className={classes.customIcon} />,
    <img src={history} alt="history" className={classes.customIcon} />,
]

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, classes.colorSecondary, {
                [classes.drawerOpen]: props.open,
                [classes.drawerClose]: !props.open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                }),
            }}
            style={{background:'red'}}
        >
            <Paper className={clsx(classes.paper, classes.colorSecondary)}>
                <div className={classes.toolbar}>
                    <Icon className={clsx(classes.icon, classes.colorSecondary)}>
                        <img className={classes.logoImg} src={logoSvg} alt="logo" />
                    </Icon>
                </div>
                <Divider />
                <List className={classes.listBack}>
                    <Link className={clsx(classes.link, classes.colorSecondary)} to={`${url}`}>
                        <ListItem button>
                            <ListItemIcon
                            // className={classes.colorSecondary}
                            >
                                <img src={dashboard} alt="dash" height={32} />
                            </ListItemIcon>
                            <ListItemText primary={t(`sidebar.dashboard`)} />
                        </ListItem>
                    </Link>
                </List>
                <List className={classes.listBack}>
                    <ListItemButton onClick={handleClick4}>
                        <ListItemIcon>
                            <img src={survey} alt="branch" className={classes.customIcon} />,
                        </ListItemIcon>
                        <ListItemText primary={t(`sidebar.orders`)} className={classes.textHover} />
                        {open4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open4} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.collapse}>
                            {ordersMenu.map((item, i) => (
                                <Link
                                    className={clsx(classes.link, classes.colorSecondary)}
                                    to={`/${item}`}
                                    key={item}
                                >
                                    <ListItem
                                        button
                                        className={clsx(path === item && classes.selectedItem)}
                                        key={item}
                                    >
                                        <ListItemIcon
                                        // className={classes.colorSecondary}
                                        >
                                            {ordersIcons[i]}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t(`sidebar.${item}`)}
                                            className="bar-menu"
                                        />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <List className={classes.listBack}>
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <img src={fastFood} alt="fast food" className={classes.customIcon} />
                        </ListItemIcon>
                        <ListItemText
                            primary={t(`sidebar.products`)}
                            className={classes.textHover}
                        />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.collapse}>
                            {productsMenu.map((item, i) => (
                                <Link
                                    className={clsx(classes.link, classes.colorSecondary)}
                                    to={`/${item}`}
                                    key={item}
                                >
                                    <ListItem
                                        button
                                        className={clsx(path === item && classes.selectedItem)}
                                        key={item}
                                    >
                                        <ListItemIcon
                                        // className={classes.colorSecondary}
                                        >
                                            {productIcons[i]}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t(`sidebar.${item}`)}
                                            className="bar-menu"
                                        />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <List className={classes.listBack}>
                    <ListItemButton onClick={handleClick1}>
                        <ListItemIcon>
                            <img src={list} alt="list" className={classes.customIcon} />
                        </ListItemIcon>
                        <ListItemText primary={t(`sidebar.users`)} className={classes.textHover} />
                        {open1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open1} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.collapse}>
                            {usersMenu.map((item, i) => (
                                <Link
                                    className={clsx(classes.link, classes.colorSecondary)}
                                    to={`/${item}`}
                                    key={item}
                                >
                                    <ListItem
                                        button
                                        className={clsx(path === item && classes.selectedItem)}
                                        key={item}
                                    >
                                        <ListItemIcon
                                        // className={classes.colorSecondary}
                                        >
                                            {usersIcons[i]}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t(`sidebar.${item}`)}
                                            className="bar-menu"
                                        />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <List className={classes.listBack}>
                    <ListItemButton onClick={handleClick2}>
                        <ListItemIcon>
                            <img src={history} alt="history" className={classes.customIcon} />,
                        </ListItemIcon>
                        <ListItemText
                            primary={t(`sidebar.settings`)}
                            className={classes.textHover}
                        />
                        {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.collapse}>
                            {settingsMenu.map((item, i) => (
                                <Link
                                    className={clsx(classes.link, classes.colorSecondary)}
                                    to={`/${item}`}
                                    key={item}
                                >
                                    <ListItem
                                        button
                                        className={clsx(path === item && classes.selectedItem)}
                                        key={item}
                                    >
                                        <ListItemIcon
                                        // className={classes.colorSecondary}
                                        >
                                            {settingsIcons[i]}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t(`sidebar.${item}`)}
                                            className="bar-menu"
                                        />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <List className={classes.listBack}>
                    <ListItemButton onClick={handleClick3}>
                        <ListItemIcon>
                            <img src={group} alt="group" className={classes.customIcon} />,
                        </ListItemIcon>
                        <ListItemText primary={t(`sidebar.leads`)} className={classes.textHover} />
                        {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open3} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.collapse}>
                            {leadsMenu.map((item, i) => (
                                <Link
                                    className={clsx(classes.link, classes.colorSecondary)}
                                    to={`/${item}`}
                                    key={item}
                                >
                                    <ListItem
                                        button
                                        className={clsx(path === item && classes.selectedItem)}
                                        key={item}
                                    >
                                        <ListItemIcon
                                        // className={classes.colorSecondary}
                                        >
                                            {leadsIcons[i]}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t(`sidebar.${item}`)}
                                            className="bar-menu"
                                        />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <Divider />
            </Paper>
        </Drawer>
    );
}
