import React, { useState } from 'react';
import './assets/css/App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { getApiFunction } from "./requests/getApi"
import Login from './pages/login';
import Logout from './pages/logout';
import Root from './pages/root';
import ProtectedRoute from './components/ProtectedRoute';
import ExtendSessionConfirm from './components/ExtendSessionConfirm';
import * as cookies from './utils/Cookies';
import { changeTheme } from "./redux/actions/themeAction"
import { useDispatch } from "react-redux"

export default function App() {
    const dispatch = useDispatch()
    React.useEffect(() => { getApiFunction() }, [])
    React.useMemo(() => {
        const lang = localStorage.getItem('i18nextLng')
        let varMode = localStorage.getItem('Mode')
        cookies.setLang(lang || 'uz')
        dispatch(changeTheme(varMode || 'dark'))
    }, [])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>

                    <ProtectedRoute exact path="/logout">
                        <Logout />
                    </ProtectedRoute>

                    <ProtectedRoute path="/">
                        <Root />
                    </ProtectedRoute>

                    <ProtectedRoute path="*">
                        <Redirect to="/" />
                    </ProtectedRoute>
                </Switch>
            </Router>

            <ExtendSessionConfirm />
        </div>
    );
}