import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from "lodash";
import { Button, ButtonGroup, Box, makeStyles, Grid,  } from '@material-ui/core';
import {
    Add as AddIcon,
    Edit as EditIcon,
    ViewListRounded,
    ViewWeekRounded,
} from '@material-ui/icons';

import OrderTable from './forms/orderComponents/OrderTable';
import Kanban from './forms/orderComponents/Kanban';
import NewOrderForm from './forms/orderComponents/NewOrderForm';
import { orderPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';

import {
    orderGet,
    orderDelete,
    statusOrderGet,
    categoryGet,
} from '../../../utils/apiRequest';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    actionRow: {
        marginBottom: 10,
    },
    hidden: {
        display: 'none',
    },
}));

export default function Orders() {
    const [data, setData] = useState([]);
    const [statusOrders, setStatusOrders] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([1]);
    const [waiting, setWaiting] = useState(false);
    const [tableView, setTableView] = useState(false);
    const [selected, setSelected] = useState([]);
    const classes = useStyles();

    const { t } = useTranslation();
    const columns = getColumns('order', t);

    const dispatch = useDispatch();

    function getOrders() {
        setWaiting(true);

        const prms = [];

        if (pagesToFetch === -1) {
            prms.push(orderGet(1, 10000));
        } else {
            for (let i of pagesToFetch) {
                prms.push(orderGet(i));
            }
        }

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setData([...data, ...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }

    function refreshPage() {
        // console.log('refresh calling');
        setData([]);
        getOrders();
    }

    function changeView(bool) {
        if (tableView !== bool) {
            setTableView(bool);
        }
    }

    function handleClickAddBtn() {
        setFormOpen({ isNew: true });
    }

    function handleClickEditBtn() {
        setFormOpen({ id: selected[0] * 1 });
    }

    function setSelectedRows(list) {
        setSelected(list);
    }

    useEffect(() => {
        statusOrderGet(1, 100).then((res) => {
            const statuses = res.data?.results;

            if (statuses && Array.isArray(statuses) && statuses.length > 0) {
                const newStatus = orderBy(statuses, ['order_number'], ['asc']);
                setStatusOrders(newStatus);
            }
        });
        categoryGet().then((res) => {
            const productCategories = res.data?.results;
            setProductCategories(productCategories);
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            getOrders();
        }, 300);
        setInterval(() => {
            getOrders();
        }, 5000)
    }, [pagesToFetch]);

    const isRowSelected = selected.length === 1 ? true : false;

    return (
        <React.Fragment>

            <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.actionRow}
            >
                <Grid item>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleClickAddBtn}
                        >
                            {t('table.buttons.add')}
                        </Button>

                        {
                            isRowSelected && (
                                <Button
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    onClick={handleClickEditBtn}
                                >
                                    {t('table.buttons.edit')}
                                </Button>
                            )
                        }
                    </Box>
                </Grid>

                <Grid item>
                    <ButtonGroup variant="text">
                        <Button
                            color={tableView ? 'default' : 'primary'}
                            title='ReacKanban'
                            onClick={() => changeView(false)}
                        >
                            <ViewWeekRounded />
                        </Button>

                        <Button
                            color={tableView ? 'primary' : 'default'}
                            title='Table'
                            onClick={() => changeView(true)}
                        >
                            <ViewListRounded />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>



            {
                tableView ? (
                    <OrderTable
                        isOrder={true}
                        rows={data}
                        count={count}
                        columns={columns}
                        waiting={waiting}
                        refreshPage={refreshPage}
                        setPagesToFetch={setPagesToFetch}
                        setSelectedRows={setSelectedRows}
                    />
                ) : (
                    <Kanban
                        columns={statusOrders}
                        data={data}
                        countBack={count}
                        // waiting={waiting}
                        refreshPage={refreshPage}
                    />

                )
            }

            <NewOrderForm
                isOpen={formOpen}
                close={() => setFormOpen(false)}
                refreshPage={refreshPage}
                collection={{
                    statusOrders,
                    propList,
                    productCategories,
                }}
            />
        </React.Fragment>
    );
}
