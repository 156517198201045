import { combineReducers } from 'redux';
import userReducer from './userReducer';
import orderReducer from './orderReducer';
import ordersReducer from './OrdersReducer';
import kanbanReducer from './KanbanReducer';
import themeReducer from "./themeReducer"
import changeLangReducer from "./lang.reducer"

const allReducers = combineReducers({
    currentUser: userReducer,
    orders: ordersReducer,
    kanban: kanbanReducer,
    theme: themeReducer,
    changeLangReducer:changeLangReducer
});

export default allReducers;
