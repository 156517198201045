import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    makeStyles,
    Typography,
    Box,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import RoomIcon from '@mui/icons-material/Room';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Controller from '../views/positions.map';
import UserAvatar from './UserAvatar';
import { useTranslation } from 'react-i18next';
import LangSwitcher from './LangSwitcher';
// import Notifications from './Notifications';
import themeImg from '../../../assets/images/theme.png';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../constants/styleConstants';
import { token } from '../../../requests/getApi';
//
// import { HiChatAlt } from "react-icons/hi";
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import Pulse from './pulseDot';

import { changeTheme } from '../../../redux/actions/themeAction';
export default function Header(props) {
    const theme = useSelector((state) => state.theme);

    const dispatch = useDispatch();

    const handleMode = (name) => {
        dispatch(changeTheme(name));
    };

    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }

    const drawerWidth = colorValues.sideBarWidthExpand;
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        grow: {
            flexGrow: 1,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth})`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        link: {
            textDecoration: 'none',
            marginRight: '20px',
        },
        menuBox: {
            marginLeft: '30px',
            marginRight: '25px',
            display: 'flex',
        },
        themeWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px',
            borderRadius: '8px',
        },
        themeImgStyle:{
            width:"65%",
            height:"25px"
        },

        // CUSTOMIZATION
        colorPrimary: {
            color: colorValues.colorPrimary,
        },
        colorBgPrimary: {
            backgroundColor: colorValues.colorBgPrimary,
        },
        colorHeader: {
            color: colorValues.colorHeader,
        },
        popover: {
            backgroundColor:"transparent",
            borderRadius:"50%",
            padding:"10px",
            boxShadow:"none",
            color:colorValues.colorBgPrimary,
        },
        themeDarkColor:{
            backgroundColor: "#002",
            width:"10%",
            color:"#fff",
            height:"30px",
        },
        themeLightColor:{
            backgroundColor: "light",
            width:"10%",
            height:"30px",
        },
        themeGreenColor:{
            backgroundColor: "#1ca39d",
            width:"10%",
            height:"30px",
            color:"#fff"
        }
    }));

    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const { url } = useRouteMatch();
    const [openModal, setOpenModal] = useState(false);

    // const handleDarkMode=()=>{
    //     setDarkMode(true)
    // }
    // const handleGreenMode=()=>{
    //     setGreenMode(true)
    // }

    // useEffect(()=>{
    //     handleGreenMode()
    // },[])

    // const openChat = () => {
    //     history.push(`https://ws.sevimli-lavash.uz/sevimli-com?token=${token}`)
    // }
    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, classes.colorBgPrimary, {
                [classes.appBarShift]: !!props.open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerToggle}
                    edge="start"
                >
                    <MenuIcon className={classes.colorPrimary} />
                </IconButton>

                <Box className={classes.menuBox}>
                    <Typography variant="h6">
                        <Link className={clsx(classes.link, classes.colorPrimary)} to="/land">
                            Landing
                        </Link>
                    </Typography>
                </Box>
                <div className={classes.grow} />
                <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                        <div>
                            <Button
                                // variant="contained"
                                {...bindTrigger(popupState)}
                                className={classes.popover}
                            >
                                <img src={themeImg} alt="theme image" className={classes.themeImgStyle}/>
                            </Button>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography sx={{ p: 2 }} className={classes.themeWrapper}>
                                    <Button  onClick={() => handleMode('dark')} className={classes.themeDarkColor}>
                                        Dark
                                    </Button>
                                    <Button
                                        
                                        onClick={() => handleMode('light')}
                                        className={classes.themeLightColor}
                                    >
                                        Light
                                    </Button>
                                    <Button  onClick={() => handleMode('green')} className={classes.themeGreenColor}>
                                        Cyan
                                    </Button>
                                </Typography>
                            </Popover>
                        </div>
                    )}
                </PopupState>
                <Button
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpenModal(!openModal)}
                    edge="start"
                    style={{ marginRight: 10 }}
                >
                    <RoomIcon className={classes.colorPrimary} />
                    <span style={{ fontSize: '14px' }} className={classes.colorHeader}>
                        {t('sidebar.couriers')}
                    </span>
                </Button>
                {/* <Notifications style={{ marginRight: '10px' }} /> */}
                <LangSwitcher />
                <UserAvatar url={url} />
                <Controller setOpenModal={setOpenModal} openModal={openModal} />
            </Toolbar>
        </AppBar>
    );
}
