import React, { useEffect, useState } from 'react';
import { set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { documentPost } from '../../../../utils/apiRequest';
import Checkbox from '@mui/material/Checkbox';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Backdrop,
    CircularProgress,
    Typography,
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { Close as CloseIcon } from '@material-ui/icons';

import Alert from '../../../../components/Alert';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../../constants/styleConstants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormController(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            zIndex: 100,
        },
        appBar: {
            position: 'fixed',
        },
        spacerHorizontal: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        dialogActions: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        backdrop: {
            zIndex: 101,
        },
        alert: {
            zIndex: 101,
        },

        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
        colorSecondary: {
            color: colorValues.colorSecondary,
            background: colorValues.colorBgSecondary,
        },
    }));
    const classes = useStyles();
    const { t } = useTranslation();
    const [input, setInput] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

    function objectTraverser(propList, selectedItem) {
        const out = {};
        propList.forEach((el) => {
            if (el.type === 'object' && selectedItem[el.label]) {
                out[el.label] = objectTraverser(el.object, selectedItem[el.label]);
            } else out[el.label] = selectedItem[el.label];
        });

        return out;
    }

    useEffect(() => {
        if (props.open.isNew) {
            setInput({});
        } else if (props.open.id) {
            // grabbing data from list and sending to form in edit mode
            const selectedItem = props.data.filter((el) => el.id === props.open.id)[0];
            const obj = objectTraverser(props.propList, selectedItem);
            setInput(selectedItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);
    const [photo, setPhoto] = useState(null);

    function handleSubmit(e) {
        e.preventDefault();

        setWaiting(true);
        const timeout = setTimeout(() => {
            if (waiting) {
                setWaiting(false);
                setAlert({ open: true, severity: 'error', message: 'Connection Error' });
            }
        }, 1000 * 20);

        const out = {};
        props.propList.forEach((el) => {
            // console.log(el.type);
            if (el.type === 'checkbox') {
                out[el.label] = input[el.label] ? input[el.label] : false;
            } else out[el.label] = input[el.label] ? input[el.label] : undefined;
        });
        if (photo !== null) {
            out.photo = photo.id;
        }
        console.log(out);
        let req;
        if (props.open.isNew) {
            req = () => props.reqPost(out);
        } else {
            req = () => props.reqPatch(props.open.id, out);
        }

        req()
            .then((res) => {
                console.log(res);
                if (res.status >= 200 && res.status < 300) {
                    setAlert({ open: true, severity: 'success', message: 'Submitted' });
                    props.handleClose();
                    props.refreshPage();
                } else {
                    setAlert({
                        open: true,
                        severity: 'error',
                        message: Object.values(res.data)[0],
                    });
                }
                setWaiting(false);
            })
            .catch((err) => {
                console.error(err);
                setWaiting(false);
            })
            .finally(() => clearTimeout(timeout));
    }

    function handleTextChange(field) {
        return function (e) {
            let val = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            const obj = { ...input };
            set(obj, field, val);

            setInput(obj);
        };
    }

    function handleFileChange(field) {
        return function (e) {
            if (!e.target.files || !e.target.files[0]) return;

            var reader = new FileReader();
            reader.onload = function () {
                var output = document.getElementById('productUpload');
                output.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);

            // console.log(e.target.files[0],"sdsa");
            const image = e.target.files[0];
            const fd = new FormData();
            fd.append('image', image, image.name);
            fd.append('name', image.name);
            documentPost(fd).then((res) => setPhoto(res.data));
        };
    }

    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen
                open={!!props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={clsx(classes.appBar, classes.colorPrimary)}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className={classes.spacerHorizontal} />

                        <Button type="submit" className={classes.colorPrimary} form="dialog-form">
                            <Typography variant="h6">{t('formControl.save')}</Typography>
                        </Button>
                    </Toolbar>
                </AppBar>

                <form onSubmit={handleSubmit} id="dialog-form">
                    <DialogTitle disableTypography>
                        <Typography variant="h4">{props.header}</Typography>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            {t('formControl.text')}
                            <div style={{ position: 'relative', top: '-5px' }}>
                                <Checkbox style={{ position: 'relative', top: '-1.5px' }} />
                                {t('productProps.is_active')}
                            </div>
                        </DialogContentText>

                        {props.render(
                            input,
                            handleTextChange,
                            handleFileChange,
                            props.statusOrders
                        )}
                    </DialogContent>
                </form>
                <Backdrop className={classes.backdrop} open={waiting}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
            <Alert
                className={classes.alert}
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
        </div>
    );
}
