import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IconButton, Avatar, Menu, MenuItem, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { connect } from 'react-redux';
import { userSet } from '../../../redux/actions/userActions';

import { getMe } from '../../../utils/apiRequest';
import Confirm from '../../../components/Confirm';
import { useSelector } from 'react-redux';
import { VALUES, DARKVALUES, GREENVALUES } from '../../../constants/styleConstants';

//


//

function UserAvatar(props) {
    const theme = useSelector((state) => state.theme);
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        link: {
            textDecoration: 'none',
        },
    
        // CUSTOMIZATION
        colorSecondary: {
            color: colorValues.colorSecondary,
        },
        colorBgSecondary: {
            backgroundColor: colorValues.colorBgSecondary,
        },
    }));
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        getMe()
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    props.setCurrentUser(res.data);
                }
            })
            .catch((err) => {
                console.error(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [0]);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <IconButton
                aria-controls="user-avatar-icon"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ padding: '2px' }}
            >
                <Avatar
                    className={classes.colorSecondary}
                    style={{ width: '30px', height: '30px' }}
                >
                    {props.currentUser.first_name[0]}
                </Avatar>
            </IconButton>

            <Menu
                id="user-avatar-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link className={clsx(classes.link, classes.colorSecondary)} to={`/profile`}>
                    <MenuItem onClick={handleClose}>{t('avatar.profile')}</MenuItem>
                </Link>

                {/* <Link
                    className={clsx(classes.link, classes.colorSecondary)}
                    to={`${props.url}/settings`}
                >
                    <MenuItem onClick={handleClose}>Settings </MenuItem>
                </Link> */}

                <Link
                    className={clsx(classes.link, classes.colorSecondary)}
                    to="#"
                    onClick={() => {
                        setConfirmOpen(true);
                    }}
                >
                    <MenuItem onClick={handleClose}>{t('avatar.logout')}</MenuItem>
                    {confirm && <Redirect to="/logout" />}
                </Link>
                <Confirm
                    open={confirmOpen}
                    header={t('avatar.logoutConfirm.header')}
                    text={t('avatar.logoutConfirm.text')}
                    handleClose={() => {
                        setConfirmOpen(false);
                    }}
                    handleConfirm={() => {
                        setConfirm(true);
                    }}
                />
            </Menu>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (payload) => dispatch(userSet(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
