import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../components/TableView';
import FormController from '../components/FormController';
import CategoriesForm from './forms/CategoriesForm';
import { categoryPropList as propList } from '../../../constants/propListConstants';
import getColumns from '../../../constants/tableColumnConstants';
import {
    categoryGet,
    categoryPost,
    categoryPut,
    categoryDelete
} from '../../../utils/apiRequest';

export default function Customers() {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [pagesToFetch, setPagesToFetch] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [idParent, setIdParent] = useState();
    const [parent, setParent] = useState(null);
    const [historyParent, setHistoryParent] = useState([1])
    
    const { t } = useTranslation();
    const columns = getColumns('category', t);

    useEffect(() => {
        setWaiting(true);

        const prms = [];
        for (let i of pagesToFetch) prms.push(categoryGet(i));

        Promise.all(prms)
            .then((res) => {
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setData([...data, ...obj]);
                setCount(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => setWaiting(false));
    }, [pagesToFetch]);

    useEffect(() => {
        setData([]);
        setPagesToFetch([1]);
    }, [refresh]);
    const handleClickBtn = (data) => {
        console.log(data)
        if(data){
            setParent(data?.id)
            setHistoryParent([...historyParent, data?.id])
        }
    }
    const handleClickbackBtn = () => {
        if(historyParent[historyParent.length - 2]){
            setParent(historyParent[historyParent.length - 2])
            let history = historyParent.pop()
            console.log(history)
        }
    }
    return (
        <>
            <Table
                rows={data}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={categoryDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
            />
            <FormController
                open={formOpen}
                data={data}
                idParent={idParent}
                propList={propList}
                header={t('propList.category')}
                render={(input, handleTextChange, handleFileChange) => ( 
                    <CategoriesForm
                        data={data}
                        input={input}
                        disableId={formOpen?.id}
                        setIdParent={setIdParent}
                        handleTextChange={handleTextChange}
                        handleFileChange={handleFileChange}
                    />
                )}
                handleClose={() => {
                    setFormOpen(false);
                }}
                refreshPage={() => window.location.reload()}
                reqPost={categoryPost}
                reqPatch={categoryPut}
            />
        </>
    );
}
