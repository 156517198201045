import {
    ADD_ORDER, REMOVE_ORDER, QUANTITY_ORDER, TOTAL_ORDER,
    SELECTED_ORDER, CLEAR_ORDER, ADD_EDIT_ORDER, ORDER_DETAILS, MODIFICATION_ORDER
} from '../actionTypes';

const initialState = {
    orderData: [],
    editedOrderData: [],
    orderTotal: 0,
    selectedOrders: [],
    orderDetails: []
};

const ordersReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_ORDER:
            return {
                ...state,
                orderData: [...state.orderData,
                    {
                        id: action.payload.id,
                        name: action.payload.name,
                        name_uz: action.payload.name_uz,
                        description: action.payload.description,
                        price: action.payload.price,
                        quantity: 1,
                        modification:null
                    }
                ]
            };
        case ADD_EDIT_ORDER:
            return {
                ...state,
                editedOrderData: [...state.editedOrderData,
                    {
                        id: action.payload.product.id,
                        name: action.payload.product.name,
                        name_uz: action.payload.product.name_uz,
                        description: action.payload.product.description,
                        price: action.payload.product.price,
                        quantity: action.payload.quantity,
                        modification: action.payload.label,
                    }
                ]
            };
        case QUANTITY_ORDER:
            return {
                ...state,
                orderData: state.orderData.map(order =>
                    order.id === action.payload.id ? {
                        ...order, quantity: action.payload.quant
                    } : order
                ),
                editedOrderData: state.editedOrderData.map(order =>
                    order.id === action.payload.id ? {
                        ...order, quantity: action.payload.quant
                    } : order
                )

            };
        case MODIFICATION_ORDER:
            return {
                ...state,
                orderData: state.orderData.map(order =>
                    order.id === action.payload.id ? {
                        ...order, modification: action.payload.label
                    } : order
                ),
                editedOrderData: state.editedOrderData.map(order =>
                    order.id === action.payload.id ? {
                        ...order, modification: action.payload.label
                    } : order
                )

            };
        case TOTAL_ORDER:
            return {
                ...state,
                orderTotal: action.payload
            };
        case SELECTED_ORDER:
            return {
                ...state,
                selectedOrders: [...state.selectedOrders, action.payload]
            };
        case REMOVE_ORDER:
            return {
                ...state,
                orderData: state.orderData?.filter(order => order.id !== action.payload),
                editedOrderData: state.editedOrderData?.filter(order => order.id !== action.payload),
                selectedOrders: state.selectedOrders?.filter(order => order !== action.payload)
            };
        case CLEAR_ORDER:
            return {
                ...state,
                orderData: [],
                editedOrderData: [],
                selectedOrders: []
            };
        case ORDER_DETAILS:
            return {
                ...state,
                orderDetails: action.payload,
            };
        default:
            return state;
    }
};

export default ordersReducer;