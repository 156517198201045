import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Grid,
    LinearProgress, TextField
} from '@material-ui/core';

import { getLang } from '../../../../utils/Cookies';
import { vendorsGet, goodsGet } from '../../../../utils/apiRequest';
import {
    addOrder,
    clearOrder,
    editQuantityOrder,
    removeOrder,
    selectedOrders
} from '../../../../redux/actions/OrderActions';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Close, ArrowBack, ArrowForward, Add, Remove, Delete, Add as AddIcon } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import orderCategory from "../../../../assets/images/orderCategory.png";
import orderProduct from "../../../../assets/images/orderProduct.png";
import { selectedKanbanClear } from '../../../../redux/actions/KanbanActions';
import { useTranslation } from 'react-i18next';
import { orderPropList as propList } from '../../../../constants/propListConstants';
import clsx from 'clsx';
// import GoodsTable from './GoodsTable';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { margin, width } from '@mui/system';
import { clearConfigCache } from 'prettier';

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: 800,
        margin: 'auto'
    },
    dialogContent: {
        padding: '16px 26px',
        width: "400px"
    },
    dialogTitle: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex!important',
        justifyContent: 'space-between',
    },
    card: {
        cursor: 'pointer',
    },
    cardContent: {
        padding: '16px !important',
        cursor: 'pointer',
        display: "flex",
        marginBottom: "10px",
        fontSize: "16px",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#166280",
        background: "rgba(0,250,154,0.1)",
        transition: "0.3s",
        "&:hover": {
            color: "lightseagreen",
        },
    },
    goodlists:{
        width:"50%",
        display:"flex",
        alignItems:"center",
    },
    cardActive: {
        background: "rgba(0,250,154,0.5)",
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem',
        marginLeft: "0.5rem"
    },
    Lists: {
        listStyleType: "none",
        width: '100%'
    },
    list: {
        padding: '9px 10px',
        borderRadius: '7px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        "&:hover": {
            backgroundColor: '#e3f2fd',
            transition: '0.3s ease-in-out',
        },
    },
    inputF: {
        justifyContent: 'space-between',
        marginBottom: '1.5rem'
    },
    addIcon: {
        cursor: "pointer",
    },

    orderCategory: {
        boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
        borderRadius: '50%',
        marginRight:"10px"
    },
    dialogAction: {
        background: "rgba(0,0,0,0.05)",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: "30%",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '7px'
    },
}))

function Categories({ productCategories, lang, handleClick }) {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            {
                productCategories.map((category) => (
                    <Grid item sm={6} key={category.id}>
                        <Card elevation={0} className={classes.card} onClick={() => handleClick(category.id)}>
                            <CardContent className={classes.cardContent}>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <img className={classes.orderCategory} src={orderCategory} width={40} height={40} alt="category" />
                                    <div style={{ marginLeft: "0.5rem", fontSize: "1rem", fontWeight: "bold" }}>
                                        {category[`name_${lang ? lang : "uz"}`]}
                                    </div>
                                </div>
                                <IconButton>
                                    <ArrowForward />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    );
}

function Products({ list, lang }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const orders = (state) => state.orders;
    const ordersData = useSelector(orders);

    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    const [quantityDialog, setQuantityDialog] = useState(false);
    const [quantity, setQuantity] = useState(null);

    function handleClick(obj) {
        console.log(obj, "this is obj in goods dialog");
        const existOrder = ordersData.orderData.find(order => order.id === obj.id) ||
            ordersData.editedOrderData.find(order => order.id === obj.id);
        if (!existOrder) {
            setQuantityDialog(true);
            setSelected(obj)
            dispatch(addOrder(obj));
            dispatch(selectedOrders(obj.id))
        } else {
            dispatch(removeOrder(obj.id));
        }
    }
    let quant = null;

    function handleInputChange(e) {
        setQuantity(e.target.value);
        quant = e.target.value;
    }

    function handleDispatch(id) {
        dispatch(editQuantityOrder({ quant, id }));
    }
    const handleCloseQuantity = () => {
        setQuantityDialog(false);
    }

    return (
        <Grid container spacing={2}>
            {
                list.map((product) => (
                    <Grid item sm={6} key={product.id}>
                        <Card elevation={0}>
                            <CardContent className={
                                ordersData.selectedOrders.includes(product.id) ?
                                    `${classes.cardContent} ${classes.cardActive}` : classes.cardContent}
                            >
                                <div style={{ display: "flex" }}>
                                    <img className={classes.orderCategory} src={orderProduct} width={40} height={40} alt="product" />
                                    <div style={{ marginLeft: "0.5rem" }}>
                                        <div style={{ fontSize: "14px", fontWeight: "bold" }}>{product[`name_${lang ? lang : "uz"}`]}</div>
                                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>Price: {product['price']}</div>
                                    </div>
                                </div>
                                {ordersData.selectedOrders.includes(product.id) ? (
                                    <IconButton onClick={() => handleClick(product)}>
                                        <Delete />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handleClick(product)}>
                                        <Add />
                                    </IconButton>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
            <Dialog
                maxWidth={'md'}
                open={quantityDialog}
                onClose={handleCloseQuantity}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                    <div style={{ fontSize: "1.2rem" }} className={classes.sectionHeader}>
                        {t('orderProps.addQuantity')}
                    </div>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseQuantity}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        size="small"
                        label={t('orderProps.quantity')}
                        defaultValue={1}
                        type="number"
                        name="quantity"
                        style={{ marginTop: "1rem" }}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) => {
                            handleInputChange(e);
                            handleDispatch(selected.id);
                        }}
                    />

                </DialogContent>
                <DialogActions className={classes.dialogAction} style={{ padding: "0.5rem" }}>
                    <Button onClick={handleCloseQuantity} color="primary">
                        {t('orderProps.cancel')}
                    </Button>
                    <Button
                        onClick={handleCloseQuantity}
                        color="primary"
                        style={{ marginLeft: "1rem" }}
                        className={classes.saveBtn}
                        autoFocus>
                        {t('orderProps.saveChanges')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}



export default function GoodsDialog({ open, setOpen, productCategories = [], tableList, setTableList, goodsCategory }) {
    const [lang, setLang] = useState("uz");
    const [category, setCategory] = useState(null);
    // console.log(category, "Kategoriyalar")
    const [loading, setLoader] = useState(false);
    const [products, setProducts] = useState([]);
    const classes = useStyles();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    var list = goodsCategory
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setCategory(null);
            setProducts([]);
        }, 300);
    };
    const [selectedItem, setSelectedItem] = useState({})
    const [goodList, setGoodList] = useState(null)
    const [goodData, setGoodData] = useState([]);
    const [state, setState] = useState({})
    
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    const handleOpenModal = (item) => {
        setLoader(false);
        setOpenModal(true);
        setSelectedItem(item)
    };


    const handleSave = () => {
        let obj = {
            name: selectedItem?.name,
            good_id: selectedItem?.id,
            unit_type_id: state?.good?.unit_type?.id || selectedItem?.unit_type?.id,
            quantity: parseInt(state?.quantity),
            price: parseInt(state?.price),
            sum: parseInt(state?.quantity) * parseInt(state?.price),
            expired_date: state?.expired_date,
            description: state?.description,
        }
        setOpenModal(false);
        setTableList([...tableList, obj])
    }

    
    useEffect(() => {
        setLang(getLang());
    }, []);

    const handleChoose = (item) => {
        vendorsGet(1, item?.id).then((res) => {
            if (res) {
                setLoader(false);
                setGoodData(res);

            }
        });

        goodsGet(1, item?.id).then((res) => {
            if (res) {
                setLoader(false);
                setGoodData(res);
                setGoodList(res?.data?.results)
            }
        });
    }
    // console.log(goodsCategory?.success?.data?.results, "good cat")

    const handleSelectItem = (item) => {
        goodsGet(1, item?.id).then((res) => {
            if (res) {
                setLoader(false);
                setGoodData(res);
                setGoodList(res?.data?.results)
            }
        });
    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={'lg'}
            className={classes.dialog}
        >
             {
                loading ? <LinearProgress /> : null
            }
           

            <DialogTitle className={classes.dialogTitle}>

                {goodList ? (
                    <div style={{
                        position: 'absolute', top: "0.5rem", left: "0.5rem"
                    }}
                        onClick={() => {
                            setGoodList(null);
                        }}
                    >
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </div>
                ) : null}
                <div style={{ marginLeft: '3rem' }}>
                    {!!goodList ? t('sidebar.goods') : t('orderProps.goods_category')}

                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <ul className={classes.Lists}>
                    {
                        goodList ? goodList?.map((item) => (
                            item.name && <li onClick={() => handleSelectItem(item)} key={item.id} className={classes.cardContent} >
                                <div className={classes.goodlists}>
                                    <img className={classes.orderCategory} src={orderProduct} width={40} height={40} alt="product" />{item?.name}
                                    </div>
                                <IconButton size="small" className={classes.addIcon} onClick={() => handleOpenModal(item)} >
                                    <Add />
                                </IconButton>
                            </li>
                        )) : goodsCategory?.success?.data?.results?.map((item) => (
                            item.name && <li onClick={() => handleChoose(item)} key={item.id} style={{ cursor: 'pointer' }}>
                                <div className={classes.cardContent}>
                                    <img src={orderCategory} width={40} height={40} alt="category" />
                                    {item?.name}
                                </div>
                            </li>
                        ))
                    }
                </ul>
                <Modal
                    className={classes.modal}
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                ><Fade in={openModal}>
                        <div className={classes.paper}>
                            <div className={classes.inputF}>
                                <h2>{selectedItem.name}</h2>
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('incomeTableProps.price')}
                                    type="number"
                                    name="quantity"
                                    style={{ marginRight: "1rem", marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, price: e.target.value })}
                                />
                                <br />
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('incomeTableProps.quantity')}
                                    type="number"
                                    name="quantity"
                                    style={{ marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, quantity: parseInt(e.target.value) })}
                                />
                                <TextField

                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('incomeTableProps.expired_date')}
                                    // defaultValue={1}
                                    type="date"
                                    name="expired_date"
                                    style={{ marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, expired_date: e.target.value })}
                                />
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    label={t('incomeTableProps.description')}
                                    type="text"
                                    name="description"
                                    style={{ marginTop: "1rem", width: "90%" }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    onChange={(e) => setState({ ...state, description: e.target.value })}
                                />
                            </div>
                            <Button size="small" onClick={handleCloseModal} color="primary">
                                {t('orderProps.cancel')}
                            </Button>
                            <Button
                                size="small"
                                onClick={handleSave}
                                color="primary"
                                style={{ marginLeft: "1rem" }}
                                className={classes.saveBtn}
                                autoFocus>
                                {t('orderProps.saveChanges')}
                            </Button>
                        </div>
                    </Fade>
                </Modal>
            </DialogContent>
            {tableList.id}
        </Dialog>
    );
}