import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Typography,
    Button,
    makeStyles,
    Divider,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { userSet } from '../../../redux/actions/userActions';

import { updateMe } from '../../../utils/apiRequest';
import Alert from '../../../components/Alert';
import { useSelector } from "react-redux"
import { VALUES, DARKVALUES, GREENVALUES } from '../../../constants/styleConstants';
import { Link } from 'react-router-dom';

//


//

function Profile(props) {

    const theme = useSelector(state => state.theme)
    let colorValues;
    if (theme.name === 'dark') {
        colorValues = VALUES;
    } else if (theme.name === 'green') {
        colorValues = GREENVALUES;
    } else {
        colorValues = DARKVALUES;
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '20px',
            borderRadius: '10px',
        },

        form: {
            minHeight: '54vh',
            display: 'flex',
            flexDirection: 'column',
        },
        accountBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
        },
        accountCircle: {
            fontSize: '60px',
        },
        spacer: {
            flexGrow: 1,
        },
        divider: {
            margin: '20px 0',
        },
        actionsBox: {
            width: '100%',
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        button: {
            minWidth: '100px',
            width: '30%',
            maxWidth: '210px',
        },
        anchor: {
            width: '100%',
            textDecoration: 'none',
            color: 'inherit',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },

        //
        colorPrimary: {
            color: colorValues.colorPrimary,
            background: colorValues.colorBgPrimary,
        },
        colorSecondary: {
            color: colorValues.colorSecondary,
            background: colorValues.colorBgSecondary,
        },
    }));
    const classes = useStyles();
    const { t } = useTranslation();
    const [user, setUser] = useState(props.currentUser);
    const [alert, setAlert] = useState({ open: false });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setUser(props.currentUser);
    }, [props.currentUser]);

    //
    function handleTextChange(field) {
        return function (e) {
            const obj = { ...user };
            obj[field] = e.target.value;
            setUser(obj);
        };
    }
    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        updateMe(user)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    props.setCurrentUser(res.data);
                    setAlert({
                        open: true,
                        severity: 'success',
                        message: t('profile.alert.success'),
                    });
                } else {
                    setAlert({
                        open: true,
                        severity: 'error',
                        message: t('profile.alert.invalid'),
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                setAlert({ open: true, severity: 'error', message: t('profile.alert.error') });
            })
            .finally(() => setSubmitted(false));
    }
    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ ...alert, open: false });
    }

    return (
        <div className={clsx(classes.root, classes.colorSecondary)}>
            <Box className={classes.accountBox}>
                <AccountCircle className={classes.accountCircle} />
                <Typography variant="h5">{`${props.currentUser.first_name} ${props.currentUser.last_name}`}</Typography>
                <Typography variant="h6">{props.currentUser.username}</Typography>
            </Box>

            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container justify="space-around">
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="first_name"
                            label={t('profile.form.firstname')}
                            required={false}
                            type="text"
                            value={user.first_name || ''}
                            onChange={handleTextChange('first_name')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="last_name"
                            label={t('profile.form.lastname')}
                            required={false}
                            type="text"
                            value={user.last_name || ''}
                            onChange={handleTextChange('last_name')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="username"
                            label={t('profile.form.username')}
                            required={false}
                            type="text"
                            value={user.username || ''}
                            onChange={handleTextChange('username')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="email"
                            label={t('profile.form.email')}
                            required={false}
                            type="email"
                            value={user.email || ''}
                            onChange={handleTextChange('email')}
                            margin="dense"
                            data-testid="add-word-input"
                        />
                    </Grid>
                </Grid>
                <Divider className={clsx(classes.divider, classes.colorPrimary)} />

                <Grid container justify="space-around">
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="phone"
                            label={t('profile.form.phone_number')}
                            required={true}
                            type="tel"
                            value={user.phone || ''}
                            onChange={handleTextChange('phone')}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="password"
                            label={t('profile.form.password')}
                            required={true}
                            type="password"
                            value={user.password || ''}
                            onChange={handleTextChange('password')}
                            margin="dense"
                        />
                    </Grid>
                </Grid>
                <Divider className={clsx(classes.divider, classes.colorPrimary)} />

                <Grid container justify="space-around">
                    <Grid item sm={5}>
                        <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            id="role"
                            label={t('profile.form.role')}
                            type="text"
                            value={user.role || ''}
                            // onChange={handleTextChange('password')}
                            margin="dense"
                        />
                    </Grid>
                </Grid>

                <div className={classes.spacer} />

                <Box className={clsx(classes.actionsBox)}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={props.handleClose}
                    >
                        <Link to="/" className={classes.anchor}>
                            {t('profile.buttons.cancel')}
                        </Link>
                    </Button>

                    <Button
                        className={classes.button}
                        variant="contained"
                        type="submit"
                        data-testid="add-word-button"
                        color="primary"
                    >
                        {t('profile.buttons.submit')}
                    </Button>
                </Box>
            </form>

            <Alert
                open={alert.open}
                severity={alert.severity}
                message={alert.message}
                onClose={handleAlertClose}
            />
            <Backdrop className={classes.backdrop} open={submitted}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (payload) => dispatch(userSet(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
